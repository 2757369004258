<template>
  <div class="DataAnalysisModel">
    <div class="data-analysis">
      <div class="data-analysis-table">
        <el-table width="100%" :data="dataAnalysisList" :show-header="false">
          <!--标题-->
          <el-table-column>
            <template slot-scope="{ row: { title }, $index }">
              <div class="title">{{ `${$index + 1}.${title}` }}</div>
            </template>
          </el-table-column>
          <!--价格-->
          <el-table-column width="100">
            <template slot-scope="{ row: { price } }">
              <div class="price">{{ price }}元/万字</div>
            </template>
          </el-table-column>
          <!--分析报告样本-->
          <el-table-column width="150">
            <template slot-scope="scope">
              <div
                class="kind"
                @click="
                  $router.push({
                    path: '/dataAnalysisInfo',
                    query: {
                      item: JSON.stringify(scope.row),
                      index: scope.$index + 1,
                    },
                  })
                "
              >
                <i class="kind-icon"></i>
                <span class="txt">分析报告样本</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "DataAnalysisModel",
  data() {
    return {
      dataAnalysisList: [
        {
          title: "词频及关联数据分析",
          price: 5,
          url: "",
          radiox: "4",
        },
        {
          title: "词频及关联数据分析",
          price: 10,
          url: "",
          radiox: "4",
        },
        {
          title: "词频及关联数据分析",
          price: 10,
          url: "",
          radiox: "4",
        },
        {
          title: "词频及关联数据分析",
          price: 10,
          url: "",
          radiox: "4",
        },
        {
          title: "词频及关联数据分析",
          price: 10,
          url: "",
          radiox: "4",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.data-analysis {
  min-height: 550px;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  &-table {
    ::v-deep .el-table th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    ::v-deep .el-table::before {
      background-color: transparent;
    }
    ::v-deep .el-table tr.el-table__row {
      td:first-child {
        .cell {
          padding-left: 0;
        }
      }
    }
    ::v-deep .el-table tr.el-table__row:hover {
      .cell {
        .title {
          color: #4587ff;
        }
        .kind {
          color: #4587ff;
          &-icon::before {
            background: url("~assets/images/goldenSentence/analysis-blue.png")
              100% no-repeat;
          }
        }
      }
    }

    ::v-deep .el-table td.el-table__cell {
      &:first-child {
        @include noselect;
      }
      border-bottom: none;
      .cell {
        @include ellipsis;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .title {
          font-weight: 500;
        }
        .kind {
          @include flex-start;
          @include noselect;
          cursor: pointer;
          &-icon {
            position: relative;
            width: 13px;
            height: 14px;
            margin-bottom: 2px;
            &::before {
              content: "";
              position: absolute;
              width: 13px;
              height: 14px;
              background: url("~assets/images/goldenSentence/analysis.png") 100%
                no-repeat;
            }
          }
          .txt {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>
