var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"DataAnalysisModel"},[_c('div',{staticClass:"data-analysis"},[_c('div',{staticClass:"data-analysis-table"},[_c('el-table',{attrs:{"width":"100%","data":_vm.dataAnalysisList,"show-header":false}},[_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var title = ref.row.title;
var $index = ref.$index;
return [_c('div',{staticClass:"title"},[_vm._v(_vm._s((($index + 1) + "." + title)))])]}}])}),_c('el-table-column',{attrs:{"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var price = ref.row.price;
return [_c('div',{staticClass:"price"},[_vm._v(_vm._s(price)+"元/万字")])]}}])}),_c('el-table-column',{attrs:{"width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"kind",on:{"click":function($event){_vm.$router.push({
                  path: '/dataAnalysisInfo',
                  query: {
                    item: JSON.stringify(scope.row),
                    index: scope.$index + 1,
                  },
                })}}},[_c('i',{staticClass:"kind-icon"}),_c('span',{staticClass:"txt"},[_vm._v("分析报告样本")])])]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }